<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              My Non-Conformance Forms
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            variant="success"
            class="mobile_create_button"
            :to="{ name: 'purchasing-ncf-create' }"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New NCF</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <b-tab
            :active="$route.params.type == 'draft'"
            lazy
            @click="changeRoute('draft')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Draft <span class="rounded-number">{{ draftNCFCount }}</span></span>
            </template>
            <draft-ncf
              class="mt-2 pt-75"
              @emitCountNCF="countNCF"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'pending'"
            lazy
            @click="changeRoute('pending')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Pending Signatories <span class="rounded-number">{{ pendingNCFCount }}</span></span>
            </template>
            <pending-ncf
              class="mt-2 pt-75"
              @emitCountNCF="countNCF"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'closed'"
            lazy
            @click="changeRoute('closed')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Closed <span class="rounded-number">{{ closedNCFCount }}</span></span>
            </template>
            <closed-ncf
              class="mt-2 pt-75"
              @emitCountNCF="countNCF"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'all-ncfs'"
            lazy
            @click="changeRoute('all-ncfs')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All NCFs <span class="rounded-number">{{ allNCFCount }}</span></span>
            </template>
            <all-ncf
              class="mt-2 pt-75"
              @emitCountNCF="countNCF"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllNcf from './all-ncf/AllNCF.vue'
import ClosedNcf from './closed-ncf/ClosedNCF.vue'
import PendingNcf from './pending-ncf/PendingNCF.vue'
import DraftNcf from './draft-ncf/DraftNCF.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    AllNcf,
    PendingNcf,
    ClosedNcf,
    DraftNcf,
  },
  data() {
    return {
      allNCFCount: 0,
      draftNCFCount: 0,
      closedNCFCount: 0,
      pendingNCFCount: 0,
      currentTabTitle: '',
      pageTabs: [
        {
          title: 'Draft',
          param: 'draft',
          count: 0,
        },
        {
          title: 'Pending Signatories',
          param: 'pending',
          count: 0,
        },
        {
          title: 'Closed',
          param: 'closed',
          count: 0,
        },
        {
          title: 'All NCFs',
          param: 'all-ncfs',
          count: 0,
        },
      ],
      businessEntityOptions: [],
      vendorOptions: [],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countNCF()
    // this.$http.get('purchase/purchase-request/create/extra-options')
    //   .then(response => {
    //     this.businessEntityOptions = response.data.businessEntityOptions ?? []
    //     this.vendorOptions = response.data.vendorOptions ?? []
    //   })
    //   .catch(error => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    countNCF() {
      this.$http.get('purchase/ncf/own/count')
        .then(response => {
          this.allNCFCount = response.data.allNCFCount
          this.closedNCFCount = response.data.closedNCFCount
          this.pendingNCFCount = response.data.pendingNCFCount
          this.draftNCFCount = response.data.draftNCFCount

          this.pageTabs.forEach(element => {
            if (element.title === 'Pending Signatories') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.pendingNCFCount
            } else if (element.title === 'Draft') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.draftNCFCount
            } else if (element.title === 'Closed') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.closedNCFCount
            } else if (element.title === 'All NCFs') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allNCFCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
